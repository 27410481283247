.modal_content_container {
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    width: 36.75rem;
    padding: 2rem 2.5rem;
    top: 50%;
    left: 50%;
    border-radius: 1.25rem;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

.title_header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.input_content_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.input_container {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.75rem;
}

.input {
    box-sizing: border-box;
    height: 3rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.input.readonly {
    background: var(--For-fonts-white, #f6f7f9);
}

.button_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 0.75rem;
}

.cancel_button {
    box-sizing: border-box;
    height: 3rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
}

.save_button {
    box-sizing: border-box;
    height: 3rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}
