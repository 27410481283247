.date_picker_container {
    display: flex;
    position: relative;
}

.schdule_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 3rem;
    gap: 0.75rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.react-datepicker-popper {
    position: absolute;
    top: 3.5rem !important;
    transform: none !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__triangle {
    display: none;
}
.react-datepicker {
    border: 0;
    padding: 1.25rem;
    border-radius: 0.625rem;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    display: flex;
    flex-direction: column;
    gap: 1.31rem;
}

.react-datepicker__header {
    border: 0;
    padding: 0;
    background-color: #fff;
}

.react-datepicker__month {
    padding: 0;
    margin: 0;
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
}
.react-datepicker__day-name {
    margin: 0;
    width: 2.81rem;
}

.react-datepicker__week {
    display: flex;
    width: 100%;
}

.react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 2.81rem;
    height: 2.81rem;
    font-size: 0.875rem;
    font-weight: 500;
}

.react-datepicker__day--selected {
    border: none;
    border-radius: 100px;
    background-color: #3a5074;
}

.icon_arrow {
    width: 0.5rem;
    height: 0.5rem;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
}

.header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_container .button_container {
    display: flex;
    align-items: center;
    gap: 0.38rem;
}

.header_container .button_container .arrow_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}
