.main_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    max-width: 83.5rem;
    padding: 2.5rem 2rem 3.75rem 2rem;
}
.img {
    width: 100%;
    background-size: contain;
}

.prevention_recurrence {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 3.75rem 3.25rem 5.62rem 3.25rem;
    gap: 1.5rem;
}

.prevention_recurrence .middle_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prevention_recurrence .content_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3.63rem;
    gap: 1.5rem;
}

.prevention_recurrence .experiences_emotion_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 3.75rem 3.25rem 21.12rem 3.25rem;
}

.prevention_recurrence .experiences_content_container {
    display: flex;
    width: 100%;
    gap: 2rem;
}

.prevention_recurrence .experiences_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1.25rem;
}

.prevention_recurrence .experiences_content .postit_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prevention_recurrence .experiences_content .postit_container .postit_title {
    position: absolute;
    color: #fff;
}

.prevention_recurrence .experiences_content .postit_container .postit {
    width: 12.25rem;
    height: 3.375rem;
}

.prevention_recurrence .experiences_content .experiences_input_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.prevention_recurrence
    .experiences_content
    .experiences_input_container
    .experiences_input {
    box-sizing: border-box;
    /* height: 33.375rem; */
    padding: 1.25rem;
    width: 100%;
    border-radius: 1.25rem;
    resize: vertical;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.cognitive_error_statistics {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 3.75rem 3.25rem 5.31rem 3.25rem;
}

.cognitive_error_statistics .content_container {
    display: flex;
    flex-direction: column;
    margin-top: 2.25rem;
    gap: 1.12rem;
    width: 100%;
}

.cognitive_error_statistics .statistics_content {
    display: flex;
    gap: 0.5rem;
}

.cognitive_error_statistics .statistics_content .statistics_input {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5.375rem;
    padding: 1.6875rem 2.5rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--primary-01-navy-5, #d1def4);
    background: var(--For-fonts-white, #fff);
    box-shadow: 0px 2px 8px 0px rgba(58, 80, 116, 0.15);
}

.img_plan_container {
    display: flex;
    justify-content: center;
    margin-top: 3.75rem;
    gap: 2.51rem;
}

.img_plan_container .img {
    width: 66.82%;
    background-size: contain;
}

/* 요즘 나의 주된 생각과 감정 작성0 */
.thought_feel {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    gap: 2.5rem;
    padding: 0 3.25rem 0 3.25rem;
}

.thought_feel_non_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    gap: 2.5rem;
    padding: 0 3.25rem 0 3.25rem;
}

.thought_feel .thought_feel_container {
    display: flex;
    position: relative;
}

.thought_feel_non_title .thought_feel_container {
    display: flex;
    position: relative;
}

.thought_feel_input_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    resize: none;
    width: 23.37%;
    height: 18.15%;
    gap: 8.16%;
}

.thought_feel_input_container0 {
    top: 13.88%;
    left: 21.88%;
}
.thought_feel_input_container1 {
    top: 12.96%;
    left: 46.74%;
}
.thought_feel_input_container2 {
    top: 35.56%;
    left: 16.79%;
}
.thought_feel_input_container3 {
    top: 32.96%;
    left: 41.41%;
}
.thought_feel_input_container4 {
    top: 37.21%;
    left: 66.04%;
}
.thought_feel_input_container5 {
    top: 55.56%;
    left: 31.52%;
}
.thought_feel_input_container6 {
    top: 57.21%;
    left: 58.02%;
}

.thought_feel_input_title {
    min-width: 2.875rem;
    box-sizing: border-box;
    padding: 2.75rem 0.81rem;
    border-radius: 0.625rem;
    color: #fff;
    background: #41465a;
}

.thought_feel_input {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    height: 100%;
    padding: 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.thought_feel_input.therapist {
    background: var(--For-fonts-7, #f6f7f9);
}

.icon_pencil {
    width: 1.875rem;
    height: 1.875rem;
}

.icon_session10_bubble {
    top: calc(-100% - 1.25rem);
    width: 24.59113rem;
    height: 2.375rem;
    background-size: contain;
    position: absolute;
}
