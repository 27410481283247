.modal_main_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    z-index: 100;
    width: 16rem;
    top: 5.31rem;
    right: 2rem;
    border-radius: 0.625rem;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 1.5rem;
}

.button_content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.button.activate {
    color: #3a5074;
    background: var(--primary-01-navy-6, #e9f0fd);
}

.button.deactivate {
    color: #020202;
    background-color: #fff;
}

.icon_activate {
    color: #3a5074;
}

.icon_deactivate {
    color: #020202;
}

.icon_calendar {
    @media (max-width: 1200px) {
        color: #d2d5d9;
    }
}

.title_color {
    font-weight: 500;
    font-size: 1rem;
    font-family: 'Noto Sans KR', sans-serif;
    color: '#020202';

    @media (max-width: 1200px) {
        color: #d2d5d9;
    }
}
