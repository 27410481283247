.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.25rem 2rem;
}

.middle_title_container {
    display: flex;
    box-sizing: border-box;
    margin-top: 2.5rem;
    height: 4.12rem;
    padding-top: 1.25rem;
    gap: 2.5rem;
    border-bottom: 0.0625rem solid #e3e5ea;
}

.middle_title {
    display: flex;
}

.middle_title.activate {
    border-bottom: 4px solid #3a5074;
    color: #3a5074;
}

.middle_title.deactivate {
    color: #9d9fa2;
}

.content_container {
    display: flex;
    flex-direction: column;
    margin-top: 1.75rem;
    border-radius: 1.25rem;
    padding-bottom: 2.25rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
}

.chart_title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 2.1875rem;
    color: #626466;
    background: #f6f7f9;
}

.chart_nondata_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
}

.page_container {
    /* position: absolute;
    right: 2rem;
    bottom: 21.75rem; */
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: flex-end;
    height: 3.94rem;
    margin-top: 2.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.page_container .button {
    display: flex;
    width: 3.625rem;
    align-items: center;
    justify-content: center;
}

.page_container .button .icon_arrow {
    width: 1rem;
    height: 1rem;
}

.page_container .button .icon_arrow.activate {
    color: #626466;
}
.page_container .button .icon_arrow.deactivate {
    color: #e3e5ea;
}
