.chart_title {
    width: 8.31rem;
    white-space: nowrap;
}

.patient_name {
    white-space: nowrap;
    width: 7.5rem;
}

.program_type_container {
    display: flex;
    align-items: center;
    width: 7.5rem;
}

.program_type {
    display: inline-block;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    border-radius: 0.3125rem;
}

.progress_container {
    display: flex;
    align-items: center;
    width: 12rem;
    height: 1.5rem;
    gap: 1rem;
}

.progress_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 3.3125rem;
    gap: 0.25rem;
}

.progress_icon {
    width: 0.75rem;
    height: 0.75rem;
}

.progress_size {
    width: 2.3125rem;
}

.progress_size.underline {
    text-decoration: underline;
}

.session_complete_date {
    display: flex;
    align-items: center;
    width: 7.5rem;
    height: 1.5rem;
}

.recent_complete_session {
    display: inline-block;
    width: 12rem;
    height: 1.5rem;
}

.icon_arrowright {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.25rem;
    color: #1100ff;
}

.user_info_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    color: #3a5074;
    border: 0.0625rem solid var(--primary-01-navy-1, #3a5074);
    background: var(--For-fonts-white, #fff);
}

.user_info_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    height: 2.5rem;
}

.filter_container {
    display: flex;
    align-items: center;
    gap: 0.875rem;
    padding: 2.25rem 2.25rem 1.25rem 2.25rem;
}

.filter_input_container {
    position: relative;
}

.filter_checkbox_container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.icon_search {
    position: absolute;
    top: 1rem;
    left: 1.25rem;
    width: 1rem;
    height: 1rem;
}

.filter_input {
    width: 27.125rem;
    height: 3rem;
    padding-left: 3rem;
    box-sizing: border-box;
    border-radius: 0.625rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
}
