.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.25rem 2rem;
    gap: 2.5rem;
}

.content_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 50.62rem;
    padding: 2.25rem 2.25rem 5rem 2.25rem;
    gap: 5rem;
    border-radius: 1.25rem;
    border: 0.0625rem solid #e3e5ea;
}

.item_container {
    display: flex;
    align-items: center;
    gap: 2.5rem;
}

.item {
    display: flex;
    flex-direction: column;
    width: 39.1875rem;
    gap: 1rem;
}
