.canvas_colorpicker {
    position: fixed;
    z-index: 11;
    height: 4.5rem;
    top: 13.625rem;
}

.options_container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 0.81rem;
    padding: 1rem 1.81rem 1rem 1.81rem;
    height: 4.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0 0.0625rem 0.325rem 0 rgba(98, 100, 102, 0.1);
}

.icon_line {
    width: 0.0625rem;
    height: 1.5rem;
    background: var(--For-fonts-5, #e3e5ea);
}

.color_button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 10rem;
    width: 2.125rem;
    height: 2.125rem;
}

.color_button.active {
    border: 0.0625rem solid #3a5074;
}

.color_icon {
    border-radius: 10rem;
    width: 1.5rem;
    height: 1.5rem;
}
