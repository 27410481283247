.main_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    max-width: 83.5rem;
    padding: 2.5rem 2rem 3.75rem 2rem;
}
.possibilities_automatic_accident {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 3.75rem 3.25rem;
    gap: 1.5rem;
}

.possibilities_automatic_accident .content_container {
    display: flex;
    box-sizing: border-box;
    gap: 6.75rem;
}

.img {
    width: 100%;
    background-size: contain;
}

.possibilities_automatic_accident .input_container {
    display: flex;
    align-items: center;
    resize: vertical;
    width: 100%;
    gap: 0.75rem;
}

.possibilities_automatic_accident .input_title {
    min-width: 2.875rem;
    box-sizing: border-box;
    padding: 1.06rem 0.81rem;
    border-radius: 0.625rem;
    color: #fff;
    background: #41465a;
}

.possibilities_automatic_accident .input {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 4rem;
    padding: 1.1875rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}
