.main_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    height: 100%;
    width: 16.25rem;
    padding: 1.5rem 0 0 0;
    border-right: 0.0625rem solid #e3e5ea;
    background: var(--For-fonts-7, #f6f7f9);
}

.menu_list_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0.75rem 1.25rem 0.75rem;
    gap: 0.25rem;
}

.li_menu {
    width: 100%;
}

.naver_cafe_button {
    display: flex;
}

/* patient */

.menu_container {
    display: flex;
    padding: 0.25rem;
    margin: 0 0.75rem;
    margin-bottom: 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(98, 100, 102, 0.1);
}

.sidebar {
    position: fixed;
    box-sizing: border-box;
    padding-top: 5.95rem;
    height: 100%;
    transition: width 0.3s ease; /* 애니메이션 효과 */
    overflow: hidden; /* 내용이 넘칠 경우 숨기기 */
    z-index: 10;
}

.sidebar.closed {
    width: 0; /* 닫힌 상태 */
}

.sidebar.open {
    min-width: 16.25rem; /* 열린 상태 */
}

.calendar_color_filter_container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 2rem;
}

.calendar_color_filter_container .item_container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0 0.75rem;
}

.calendar_color_filter_container .item_container .item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.icon_menu {
    width: 1.5rem;
    height: 1.5rem;
}

.icon_cafe_banner {
    width: 100%;
    height: 6.25rem;
}
