.modal_content_container {
    position: fixed;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 36.75rem;
    padding: 2rem 2.5rem;
    top: 50%;
    left: 50%;
    border-radius: 1.25rem;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

.title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.program_list_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
    gap: 1.75rem;
}

.program_list_container .item {
    display: flex;
    align-items: center;
}

.total_cancel_price_container {
    display: flex;
    flex-direction: column;
    margin-top: 2.25rem;
    gap: 0.75rem;
}

.total_cancel_price_container .input {
    display: flex;
    box-sizing: border-box;
    height: 3rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.625rem;
    border: 1px solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.button_container {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 0.75rem;
}

.button_container .cancel_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    height: 3rem;
    border-radius: 0.625rem;
}

.button_container .save_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    height: 3rem;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}
