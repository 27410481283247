.patient_main_container {
    display: flex;
    flex-direction: column;
    max-width: 83.5rem;
    padding: 2.5rem 2rem 3.75rem 2rem;
}

.therapist_main_container {
    display: flex;
    flex-direction: column;
    max-width: 83.5rem;
    padding: 2.5rem 2rem 3.75rem 2rem;
}
