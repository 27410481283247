.main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10.375rem;
    margin-bottom: 3.75rem;
    gap: 2.5rem;
}

.content_container {
    display: flex;
    flex-direction: column;
    width: 75rem;
    align-items: flex-start;
}

.receipt_content_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    gap: 2.5rem;
    padding: 2.25rem;
    border-radius: 20px;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
}

.receipt_content_title {
    margin-bottom: 1.25rem;
}

.item_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.item {
    display: flex;
    gap: 2.625rem;
}

.item_title {
    width: 23.43rem;
}

.item_content {
}
