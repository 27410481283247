.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.25rem 2rem;
}

.menu_title_container {
    display: flex;
    gap: 2.5rem;
    margin-top: 2.5rem;
    padding-top: 1.25rem;
    border-bottom: 0.0625rem solid #e3e5ea;
}

.menu_title {
    display: inline-block;
    height: 3.5rem;
    align-items: flex-start;
}

.patient_session_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 1.25rem;
    max-height: 72.5rem;
    margin-top: 1.75rem;
    padding: 2.25rem 0;
    border-radius: 1.25rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
}

.menu_container {
    display: flex;
    padding: 0 2.1875rem;
    justify-content: space-between;
}
.session_menu_container {
    display: flex;
    gap: 0.75rem;
}

.reservations_progress_status_container {
    display: flex;
}

.reservations_count {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-right: 0.75rem;
}

.reservations_count_plus_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 3rem;
    gap: 0.625rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
    border: 1px solid var(--primary-01-navy-1, #3a5074);
    background: var(--For-fonts-white, #fff);
}

.progress_status_container {
    display: flex;
    align-items: center;
    border-radius: 0.625rem;
    padding: 0 0.1875rem;
    background: #eef0f3;
}

.session_content_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.75rem;
    padding: 0 2.1875rem;
    background-color: #f6f7f9;
}

.session_title {
    color: #626466;
    width: 7.5rem;
}

.sessino_list_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.1875rem;
    height: 4.0625rem;
}

.border_bottom {
    border-bottom: 0.0625rem solid #e3e5ea;
}

.current_session {
    width: 7.5rem;
}

.status {
    display: flex;
    padding: 0.25rem 0.5rem;
    border-radius: 0.3125rem;
    color: #626466;
}

.reserve_info_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    height: 2.5rem;
    margin-left: 9.375rem;
    border-radius: 0.3125rem;
    color: #3a5074;
    border: 0.0625rem solid var(--primary-01-navy-1, #3a5074);
    background: var(--For-fonts-white, #fff);
}

.preparing_service {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    color: #9d9fa2;
}
