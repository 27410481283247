.canvas_options {
    position: fixed;
    z-index: 11;
    height: 4.5rem;
    top: 8.125rem;
}

.options_container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 0.94rem;
    padding: 0.94rem;
    height: 4.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 0.0625rem 0.375rem 0px rgba(98, 100, 102, 0.1);
}

.icon_line {
    width: 0.0625rem;
    height: 1.5rem;
    background: var(--For-fonts-5, #e3e5ea);
}

.icon {
    color: #fff;
    width: 2.625rem;
    height: 2.625rem;
}

.icon.active {
    color: #e9f0fd;
}
