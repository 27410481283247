.main_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    max-width: 83.5rem;
    padding: 2.5rem 2rem 3.75rem 2rem;
}
.video_list_container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.25rem;
}

.vidoe_list_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 2.625rem;
    padding: 0.5rem 0.875rem;
    border-radius: 0.5rem;
}

.vidoe_list_button.activate {
    outline: 2px solid var(--primary-01-navy-1, #3a5074);
    background: var(--primary-01-navy-7, #f6f9ff);
}

.vidoe_list_button.deactivate {
    outline: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}
