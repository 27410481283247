.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.5rem;
    padding: 2.25rem 2rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header .input_container {
    display: flex;
    align-items: center;
    position: relative;
}

.header .input_container .input {
    display: flex;
    box-sizing: border-box;
    width: 27.125rem;
    height: 3rem;
    padding: 0 3rem;
    border-radius: 0.625rem;
    outline: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.header .input_container .input.activate {
    outline: 0.1875rem solid var(--primary-01-navy-1, #3a5074);
}

.icon_search_container {
    position: absolute;
    left: 1.25rem;
}

.icon_search {
    width: 1rem;
    height: 1rem;
}
