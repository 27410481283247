body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
}

div {
    white-space: pre-line;
    /* line-height: 0px; rbc 적용시 주석 처리?*/
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

button {
    border: none;
    padding: 0px;
    line-height: 0px;
    background-color: transparent;
    cursor: pointer;

    &:active {
        background-color: #fff;
        opacity: 0.4;
        -webkit-tap-highlight-color: transparent;
    }
}

input {
    border-radius: 0;
    padding: 0px;
    margin: 0px;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

input:focus {
    outline: 0.0625rem solid #e3e5ea;
}
textarea:focus {
    outline: none !important;
    box-shadow: none !important;
}

textarea {
    border-radius: 0;
    resize: vertical;
}

pre:focus {
    outline: 0.0625rem solid #e3e5ea;
}

pre {
    white-space: pre-wrap;
    overflow: auto;
    margin: 0px;
    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
}

code {
    font-family:
        source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.safe-area {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    flex: 1;
}

.backgroundTop {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 100;
    top: 0;
}
.backgroundBottom {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 100;
    bottom: 0;
}

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);

    font-size: 16px;
}

/* 모바일 디바이스 */

@media (max-width: 1024px) {
    :root {
        font-size: 6px; /* 큰 태블릿 전용 폰트 크기 */
    }
}

/* 데스크탑 디바이스 */
@media (min-width: 1025px) and (max-width: 1199px) {
    :root {
        font-size: 8px; /* 작은 데스크탑 전용 폰트 크기 */
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    :root {
        font-size: 10px; /* 중간 데스크탑 전용 폰트 크기 */
    }
}

@media (min-width: 1440px) and (max-width: 1599px) {
    :root {
        font-size: 12px; /* 중대형 데스크탑 전용 폰트 크기 */
    }
}

@media (min-width: 1600px) and (max-width: 1919px) {
    :root {
        font-size: 14px; /* 1920px 기준 폰트 크기 */
    }
}

@media (min-width: 1920px) and (max-width: 2560px) {
    :root {
        font-size: 16px; /* 대형 화면 전용 폰트 크기 */
    }
}

@media (min-width: 2561px) {
    :root {
        font-size: 20px; /* 초대형 화면 전용 폰트 크기 */
    }
}
