.basic_info_container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.save_info_button {
    display: flex;
    box-sizing: border-box;
    width: 13.75rem;
    padding: 1.1875rem 3.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}

.basic_info {
    display: flex;
    width: 100%;
    gap: 1.75rem;
}

.info_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.75rem;
}

.input_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.margin_bottom {
    margin-bottom: 2.625rem;
}

.input {
    width: 100%;
    box-sizing: border-box;
    height: 4rem;
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.read_only_input {
    width: 100%;
    box-sizing: border-box;
    height: 4rem;
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-7, #f6f7f9);
}

.email_dropdown {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    text-align: justify;
    height: 4rem;
    padding: 1.1875rem 1.25rem;
    /* border: ${(props) => (props.$isFocused === props.$index ? '0.1875rem solid #3A5074' : '1px solid #e3e5ea')}; */
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.icon_arrowbottm {
    position: absolute;
    top: 1.5rem;
    right: 1.25rem;
}

.multi_input_container {
    display: flex;
    position: relative;
    align-items: center;
    gap: 0.75rem;
}

.event_policy_container {
    display: flex;
    flex-direction: column;
    width: 46.75rem;
    gap: 1rem;
}

.event_content_container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.5rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
}

.event_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.event_item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.event_detail_button {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.icon_arrowright {
    width: 1rem;
    height: 1rem;
    color: #3a5074;
}
