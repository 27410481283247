@function resize($rem) {
    @return calc(var(--window-ratio) * #{$rem});
}

.main_container {
    display: flex;
    align-items: center;
    height: 100vh;
}

.view_container {
    display: flex;
    width: 63.96%;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.content_container {
    display: block;
    width: 31.75rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.logo {
    width: 3.75rem;
    height: 3.75rem;
}

.login_title {
    margin-top: 2.5rem;
}

.signup_title {
    display: flex;
    justify-content: center;
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
    gap: 0.75rem;
}

.icon_kakao_logo {
    position: absolute;
    left: 1.25rem;
    width: 1.75rem;
    height: 1.75rem;
}

.kakao_login_button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 1.75rem;
    width: 100%;
    padding: 1.125rem 1.25rem 1.125rem 1.25rem;
    border-radius: 0.55rem;
    background-color: #fae100;
}

.and_line_container {
    display: flex;
    align-items: center;
    margin: 1.75rem 0 1.75rem 0;
    gap: 0.75rem;
}

.and_line {
    width: 14.06rem;
    height: 0.062rem;
    background-color: #e3e5ea;
}

.input_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.75rem;
}

.input_content_container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
}

.login_input {
    box-sizing: border-box;
    height: 4rem;
    padding: 1.18rem 1.25rem 1.18rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
}

.account_assistant_container {
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.save_id {
    display: flex;
    gap: 0.75rem;
}

.input_checkbox {
    width: 4rem;
    height: 4rem;
}

.length_line {
    width: 0.125rem;
    height: 1rem;
    background: #e3e5ea;
}

.find_account {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.login_button {
    width: 100%;
    height: 4rem;
    margin-top: 2.5rem;
    border-radius: 0.625rem;
    color: #fff;
    background: #3a5074;
}
