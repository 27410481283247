.content_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.25rem 0 2.25rem 0;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.content_container .search_result {
    padding: 0 2.25rem 1.25rem 2.25rem;
}

.content_container .title_container {
    display: flex;
    align-items: center;
    gap: 7.31rem;
    height: 2.75rem;
    padding: 0 2.2rem;
    background: var(--For-fonts-7, #f6f7f9);
}

.content_container .title_container .title_item {
    display: flex;
    width: 8.9375rem;
    color: #626466;
}

.content_container .reverse_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content_container .reverse_container .reverse_item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    gap: 7.31rem;
    height: 4.625rem;
    padding: 0 2.2rem;
}

.content_container .reverse_container .reverse_item.border_bottom {
    border-bottom: 0.0625rem solid #e3e5ea;
}
