.patient_picker {
    display: flex;
    flex-direction: column;
    position: absolute;
    box-sizing: border-box;
    overflow-y: scroll;
    top: 3.5rem;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 28.0625rem;
    padding-bottom: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.patient_picker .input_container {
    display: flex;
    position: relative;
    align-items: center;
    padding: 1.25rem 1.5rem 1.19rem 1.5rem;
}

.patient_picker .input_container .input {
    box-sizing: border-box;
    width: 100%;
    height: 3rem;
    padding: 0.6875rem 1.25rem 0.6875rem 3rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.patient_picker .content_container {
    display: flex;
    flex-direction: column;
}

.patient_picker .content_container .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.25rem 1.5rem;
    border-top: 0.0625rem solid #e3e5ea;
}

.patient_picker .content_container .content .group {
    margin-bottom: 1.25rem;
}

.patient_picker .content_container .content .group_item_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.icon_search {
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: 2.75rem;
}
