.main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10.375rem;
    margin-bottom: 3.75rem;
    gap: 2.5rem;
    padding: 0 22.5rem;
}

.content_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.75rem;
    justify-content: center;
}

.policy_content_container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.policy_content {
    display: flex;
    flex-direction: column;

    margin-top: 1.25rem;
}

.li {
    list-style: none;
}

/* 회원가입 약관 */

.content_container .content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}
