.main_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    max-width: 83.5rem;
    padding: 2.5rem 2rem 3.75rem 2rem;
}

.img1 {
    width: 100%;
    /* width: 83.5rem; */
    margin-top: 2.25rem;
    background-size: contain;
}

.img {
    width: 100%;
    /* width: 83.5rem; */
    background-size: contain;
}

/* 요즘 나의 주된 생각과 감정 */
.thought_feel {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    gap: 2.5rem;
    padding: 3.75rem 3.25rem;
}

.thought_feel_container {
    display: flex;
    position: relative;
}

.patient_brain_container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
}

.patient_brain_container .thought_feel_input_container {
    display: flex;
    position: absolute;
    align-items: center;
    resize: none;
    width: 23.37%;
    height: 8.52%;
}

.patient_brain_container .thought_feel_input_container0 {
    top: 22.59%;
    left: 21.88%;
}
.patient_brain_container .thought_feel_input_container1 {
    top: 17.04%;
    left: 46.74%;
}
.patient_brain_container .thought_feel_input_container2 {
    top: 40.55%;
    left: 16.79%;
}
.patient_brain_container .thought_feel_input_container3 {
    top: 37.04%;
    left: 41.41%;
}
.patient_brain_container .thought_feel_input_container4 {
    top: 41.29%;
    left: 66.04%;
}
.patient_brain_container .thought_feel_input_container5 {
    top: 55.93%;
    left: 31.52%;
}
.patient_brain_container .thought_feel_input_container6 {
    top: 59.44%;
    left: 58.02%;
}

.therapist_brain_container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
}

.therapist_brain_container .thought_feel_input_container {
    display: flex;
    position: absolute;
    align-items: center;
    resize: none;
    width: 27.92%;
    height: 8.52%;
}

.therapist_brain_container .thought_feel_input_container0 {
    top: 22.59%;
    left: 16.47%;
}
.therapist_brain_container .thought_feel_input_container1 {
    top: 17.04%;
    left: 46.11%;
}
.therapist_brain_container .thought_feel_input_container2 {
    top: 40.55%;
    left: 10.32%;
}
.therapist_brain_container .thought_feel_input_container3 {
    top: 37.04%;
    left: 39.75%;
}
.therapist_brain_container .thought_feel_input_container4 {
    top: 41.29%;
    left: 69.16%;
}
.therapist_brain_container .thought_feel_input_container5 {
    top: 55.93%;
    left: 27.92%;
}
.therapist_brain_container .thought_feel_input_container6 {
    top: 59.44%;
    left: 59.58%;
}

.thought_feel_input_title {
    min-width: 2.875rem;
    box-sizing: border-box;
    padding: 2.75rem 0.81rem;
    border-radius: 0.625rem;
    color: #fff;
    background: #41465a;
}

.thought_feel_input {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    resize: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 5.36%;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

/* 변화하고 싶은 증상과 이유 */

.symptom_barrier {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 3.25rem 4.06rem 3.25rem;
    gap: 3.75rem;
}

.desired_symptom_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.desired_symptom_input {
    /* min-height: 20.375rem; */
    resize: vertical;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.barrier_input_container {
    display: flex;
    align-items: flex-start;
    margin-top: 2.5rem;
    gap: 2rem;
}

.barrier_input_container .barrier_input_box {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    /* width: 100%; */
    width: 24.75rem;
}

.barrier_input_box .barrier_input {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    /* min-height: 20.375rem; */
    padding: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

/* 장점과 목표 */

.advantage_goal {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 1.69rem;
    /* padding: 2.5rem 3.25rem 24.12rem 3.25rem; */
    padding: 2.5rem 3.25rem;
}

.advantage_input {
    display: flex;
    /* min-height: 9rem; */
    box-sizing: border-box;
    resize: vertical;
    padding: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
}

.advantage_goal_content {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}

.step_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.padding_one {
    padding-top: 5rem;
}
.padding_two {
    padding-top: 2.5rem;
}

.step_container .step_one {
    width: 100%;
    min-height: 2.5rem;
    border-radius: 0.625rem;
    background: #a0c0d0;
}
.step_container .step_two {
    width: 100%;
    min-height: 5rem;
    border-radius: 0.625rem;
    background: #7196a8;
}
.step_container .step_three {
    width: 100%;
    min-height: 7.5rem;
    border-radius: 0.625rem;
    background: #386277;
}

.goal_input {
    width: 100%;
    box-sizing: border-box;
    /* min-height: 10.625rem; */
    border-radius: 0.625rem;
    resize: vertical;
    padding: 1.25rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.video_popup_container {
    position: fixed;
    top: 5.875rem;
    right: 0;
    margin: 2.25rem 2rem;
    z-index: 10;
}

.video_resize_box {
    position: absolute;
    cursor: nwse-resize;
    bottom: 0;
    left: 0;
    width: 0.625rem;
    height: 0.625rem;
}

.video_container {
    display: flex;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 1.25rem;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.25);
}

.video_container .close_button {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 20;
}

.video_container .close_button .icon_x {
    width: 1.5rem;
    height: 1.5rem;
    color: #fff;
}
