.main_container {
    display: flex;
    flex-direction: column;
    position: fixed;
    box-sizing: border-box;
    width: 16.25rem;
    height: 100%;
    padding: 1.5rem 0.75rem;
    gap: 1.25rem;
    flex-shrink: 0;
    border-right: 0.0625rem solid #e3e5ea;
    background: var(--For-fonts-white, #fff);
}

.patient_info_button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 2.625rem;
    padding: 0.6875rem 3.1875rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
}

.menu_list_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.li_menu {
    width: 100%;
}

.naver_cafe_button {
    display: flex;
    padding: 0.75rem 0;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
}

/* patient */

.menu_container {
    display: flex;
    padding: 0.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
    box-shadow: 0px 0.0625rem 0.375rem 0px rgba(98, 100, 102, 0.1);
}

.icon_arrowright {
    width: 0.75rem;
    height: 0.75rem;
    color: #020202;
}

.icon_menu {
    width: 1.5rem;
    height: 1.5rem;
}
