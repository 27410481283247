.modal_content_container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 58.13%;
    height: 100%;
    padding: 2.25rem 2.25rem 3.75rem 2.25rem;
    top: 50%;
    right: 1.2rem;
    transform: translateY(-50%);
    background-color: #fff;
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.title .reserve_cancel_container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.title .reserve_cancel_container .reserve_cancel {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    gap: 0.25rem;
    border-radius: 0.3125rem;
    background: var(--For-fonts-3, #9d9fa2);
}

.content_container {
    display: flex;
    gap: 1.75rem;
}

.content_container .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.content_container .input_container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.content_container .input_container .input {
    width: 100%;
}

.content_container .input_container .schedule_time_container {
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    gap: 0.75rem;
    height: 3rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.content_container .input_container .schedule_time_container .button_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;
}

.content_container .input_container .schedule_time_container .time_button {
    display: flex;
    align-items: center;
    height: 3rem;
    width: 100%;
}

.content_container .input_container .schedule_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 3rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.content_container .input_container .drop_down_button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.content_container .input_container .input_readonly {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 1.25rem;
    width: 100%;
    height: 3rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-7, #f6f7f9);
}

.memo_container {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    gap: 0.75rem;
}

.memo_container .memo {
    min-height: 11.25rem;
    padding: 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
}

.no_show {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    gap: 1.75rem;
}

.no_show .content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.button_container {
    display: flex;
    align-items: center;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.button_container .schedule_cancel_button {
    box-sizing: border-box;
    height: 4rem;
    padding: 1.1875rem 3.75rem;
    color: #ff5833;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
}

.button_container .cancel_button {
    height: 4rem;
    padding: 1.1875rem 3.75rem;
    border-radius: 0.625rem;
}

.button_container .save_button {
    margin-left: 0.75rem;
    height: 4rem;
    padding: 1.1875rem 3.75rem;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
}
