.header_container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 120;
    align-items: center;
    background-color: #fff;
    border-bottom: 0.0625rem solid #e3e5ea;
}

.icon_header_logo {
    width: 11.56rem;
    height: 2.125rem;
}

.header_logo_container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 5.875rem;
    border-right: 0.0625rem solid #e3e5ea;
}

.header_logo_container.activate {
    justify-content: space-between;
    padding: 1.875rem 0.75rem;
    width: 16.25rem;
    gap: 1.68rem;
}
.header_logo_container.deactivate {
    justify-content: center;
    width: 5.06rem;
}

.header_content_container {
    display: flex;
    width: 100%;
    height: 5.875rem;
    padding-right: 2rem;
    justify-content: flex-end;
    align-items: center;
}

.header_content_container .header_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 2rem;
    gap: 1.5rem;
}

.header_content_container .header_content .menu {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.blackboard_container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.feedback_change_mode_button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 0.5rem;
    height: 3.25rem;
    padding: 0.875rem 0.75rem;
    border-radius: 0.625rem;
    background: var(--For-fonts-6, #eef0f3);
}

.blackboard_button {
    display: flex;
    box-sizing: border-box;
    height: 3.25rem;
    gap: 0.5rem;
    padding: 0.875rem 0.75rem;
    border-radius: 0.625rem;
    background: var(--primary-01-navy-1, #3a5074);
}

.icon_blackboard {
    width: 1.5rem;
    height: 1.5rem;
}

.organ_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem 0.75rem;
    gap: 0.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.haler_profile_button {
    display: flex;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.icon_arrowbottom {
    margin-left: 0.75rem;
    width: 0.5rem;
    height: 0.5rem;
}

/* 환자 */

.program_payment_button {
    display: flex;
    box-sizing: border-box;
    width: 7rem;
    height: 3.25rem;
    border-radius: 0.8rem;
    padding: 0.125rem;
    background: linear-gradient(240deg, #a3c5d2 0%, #7792b0 74%, #61789e 100%);
}

.progran_payment_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0.625rem;
    z-index: 1; /* 배경보다 위에 표시되도록 설정 */
    background-color: #fff;
}

.name_content {
    display: flex;
    flex-direction: column;
}

.patient_profile_button {
    display: flex;
    box-sizing: border-box;
    height: 3.25rem;
    padding: 0.875rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.icon_fold {
    width: 1.5rem;
    height: 1.5rem;
}

.icon_menu {
    width: 1.5rem;
    height: 1.5rem;
}

.icon_map {
    width: 1.5rem;
    height: 1.5rem;
}
