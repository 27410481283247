/* Feedback */

.feedback_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.feedback_container .feedback_content_container {
    display: flex;
    flex-direction: column;
    border-radius: 0.9375rem;
    overflow: hidden;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.feedback_container .feedback_content_container .feedback_title {
    padding: 1rem 1.25rem;
    border-bottom: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    color: #3a5074;
}

.feedback_container .feedback_content_container .feedback_textarea {
    padding: 1.25rem;
    height: 100%;
    border: none;
}

.feedback_container .feedback_content_container .feedback_textarea.edit {
    color: #3a5074;
    background: var(--primary-01-navy-7, #f6f9ff);
}

.feedback_container .button_container {
    display: flex;
    gap: 0.75rem;
}

.feedback_container .button_container .cancel_button {
    display: flex;
    box-sizing: border-box;
    height: 3rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
    background: var(--For-fonts-white, #fff);
}

.feedback_container .button_container .save_button {
    display: flex;
    box-sizing: border-box;
    height: 3rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}
