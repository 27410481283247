.main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    margin-top: 10.375rem;
    margin-bottom: 3.75rem;
}

.program_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 75rem;
}

.icon_check {
    width: 2.5rem;
    height: 2.5rem;
}

.main_title {
}

.program_category_list_container {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    border-radius: 0.9375rem;
    border: 1.5px solid #e3e5ea;
    background: #fff;
}

.program_category_list_container .button {
    display: flex;
    box-sizing: border-box;
    height: 4rem;
    padding: 1rem 3rem;
    border-radius: 0.75rem;
}

.program_category_list_container .button.activate {
    background: linear-gradient(90deg, #4d717f 0%, #313e6d 100%);
    box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.15);
}

.program_category_list_container .button.deactivate {
    background: var(--For-fonts-white, #fff);
}

.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}

.price {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1.75rem;
    right: 2.25rem;
    gap: 0.5rem;
}

.program_select_button {
    display: flex;
    box-sizing: border-box;
    padding: 0 3.75rem;
    height: 4rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}

.program_select_button.loading {
    color: #fff;
    background-color: #e3e5ea;
}

.approval_loading_container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.approval_loading {
    width: 1.625rem;
    height: 1.625rem;
}
