.main_container {
    display: flex;
    flex-direction: column;
    max-width: 83.5rem;
    padding: 2.5rem 2rem 3.75rem 2rem;
}

.image_input_container {
    margin-top: 2.25rem;
    position: relative;
    display: inline-block;
}

.physical_symptoms_input {
    width: 100%;
    height: 100%;
    border: none;
    resize: vertical;
}

.physical_symptoms_input_container {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    padding: 0 1.02%;
    height: 4.44%;
    width: 10%;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.physical_symptoms_input_container.brain {
    top: 16.3%;
    left: 21.55%;
}
.physical_symptoms_input_container.breath {
    top: 32%;
    left: 24.62%;
}
.physical_symptoms_input_container.muscle_tension {
    top: 44.3%;
    left: 16.29%;
}
.physical_symptoms_input_container.intestine {
    top: 61.57%;
    left: 17.85%;
}
.physical_symptoms_input_container.peripheral_blood_vessels {
    top: 79.2%;
    left: 14.1%;
}
.physical_symptoms_input_container.salivary_glands {
    top: 27.6%;
    right: 21.74%;
}
.physical_symptoms_input_container.heart {
    top: 43%;
    right: 16.1%;
}
.physical_symptoms_input_container.camouflage {
    top: 60.5%;
    right: 14.73%;
}
.physical_symptoms_input_container.sweat_glands {
    top: 76.95%;
    right: 14.1%;
}

.img1 {
    width: 100%;
    background-size: contain;
}

.img {
    width: 100%;
    background-size: contain;
}

.situation_emotion_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.74rem 3.25rem 21.12rem 3.25rem;
}

.situation_container {
    display: flex;
    width: 100%;
    margin-top: 6.31rem;
    gap: 1.25rem;
}

.situation_content_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.situation_content {
    min-width: 8.06rem;
    white-space: nowrap;
    color: #626466;
}

.situation_input_contaienr {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.situation_input {
    width: 100%;
    border-radius: 0.625rem;
    padding: 1.19rem 1.25rem;
    resize: vertical;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.situation_emotion {
    display: flex;
    width: 6.5rem;
    height: 14.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    color: #fff;
    background: #41465a;
}

.emotion_container {
    display: flex;
    width: 100%;
    gap: 1.25rem;
    margin-top: 4.81rem;
}

.emotion_content_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.title_score_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.score_container {
    position: relative;
    display: flex;
    align-items: center;
}
.score_input {
    box-sizing: border-box;
    width: 13.125rem;
    height: 4rem;
    padding: 0.6875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.emotion_icon_container_box {
    display: flex;
    height: 11rem;
    align-items: center;
    box-sizing: border-box;
    gap: 0.75rem;
}

.emotion_icon_container.deactivate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 1rem;
    gap: 1.25rem;
    opacity: 0.5;
}
.emotion_icon_container.activate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 1rem;
    gap: 1.25rem;
    border-radius: 0.625rem;
    border: 0.1875rem solid #41465a;
    background: #f2f4fc;
}

.icon_container {
    display: flex;
    align-items: center;
    height: 5.81rem;
}

.experiences_emotion_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 3.75rem 3.25rem 3.74rem 3.25rem;
}

.experiences_content_container {
    display: flex;
    width: 100%;
    gap: 2rem;
}

.experiences_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1.25rem;
}

.postit_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.postit_title {
    position: absolute;
    color: #fff;
}

.postit {
    width: 12.25rem;
    height: 3.375rem;
}

.experiences_input_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.experiences_input {
    box-sizing: border-box;
    padding: 1.25rem;
    width: 100%;
    /* height: 40.275rem; */
    border-radius: 1.25rem;
    resize: vertical;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}
