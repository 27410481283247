.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.25rem 2rem;
}

.middle_title_container {
    display: flex;
    box-sizing: border-box;
    margin-top: 2.5rem;
    height: 4.12rem;
    padding-top: 1.25rem;
    gap: 2.5rem;
    border-bottom: 0.0625rem solid #e3e5ea;
}

.middle_title {
    display: flex;
}

.middle_title.activate {
    border-bottom: 0.25rem solid #3a5074;
    color: #3a5074;
}

.middle_title.deactivate {
    color: #9d9fa2;
}

.content_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 1.75rem;
    border-radius: 1.25rem;
    padding-bottom: 2.25rem;
    outline: 0.0625rem solid #e3e5ea;
    background: #fff;
}

.search_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.25rem 2.25rem 1.25rem 2.25rem;
}

.search_container .program_add_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}

.icon_search {
    position: absolute;
    left: 1.25rem;
}

.content_container .filter_button_container {
    display: flex;
    padding: 0.19rem;
    border-radius: 0.625rem;
    background: var(--For-fonts-6, #eef0f3);
}

.content_container .filter_button_container .filter_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.5625rem;
    height: 2.635rem;
}

.content_container .filter_button_container .filter_button.activate {
    border-radius: 0.5rem;
    color: #3a5074;
    background: var(--For-fonts-white, #fff);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.15);
}
.content_container .filter_button_container .filter_button.deactivate {
    color: #9d9fa2;
}

.chart_title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.63rem 2.19rem;
    color: #626466;
    background: #f6f7f9;
}

.no_program_group {
    display: flex;
    align-items: center;
    gap: 1.31rem;
}

.status_origin_price_group {
    display: flex;
    align-items: center;
}

.chart_title {
    width: 13.875rem;
    color: #626466;
}

.chart_content_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.06rem;
    padding: 0 2.19rem;
}

.chart_content_container.border_bottom {
    border-bottom: 0.0625rem solid #e3e5ea;
}

.item_no {
    width: 1.6875rem;
}
.item_program_name {
    width: 24rem;
}
.item_status {
    width: 7.5rem;
}
.item_origin_price {
    width: 7.5rem;
}
.item_price {
    width: 12.25rem;
}
.item_branch_name {
    width: 12.25rem;
}
.item_category {
    width: 12.25rem;
}
.item_detail {
    width: 10.25rem;
}

.program_detail_button {
    box-sizing: border-box;
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--primary-01-navy-1, #3a5074);
    color: #3a5074;
    background: var(--For-fonts-white, #fff);
}

.icon_dot_menu {
    width: 13.875rem;
    text-align: right;
}

.drop_down_container {
    display: flex;
    position: absolute;
    flex-direction: column;
    box-sizing: border-box;
    top: 3rem;
    z-index: 10;
    width: 100%;
    gap: 1.5rem;
    padding: 1.25rem 1.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.drop_down_item_button {
    display: flex;
    text-align: left;
}
