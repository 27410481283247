.main_container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content_container {
    display: flex;
    padding-top: 5.875rem;
}

.content_container.activate {
    margin-left: 16.25rem;
}

.content_container.deactivate {
    width: 100%;
}
