.modal_content_container {
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    width: 28.312rem;
    padding: 4.187rem 2.5rem 2rem 2.5rem;
    top: 50%;
    left: 50%;
    border-radius: 1.25rem;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

.logo {
    position: absolute;
    top: -3.437rem;
    left: 10.75rem;
    width: 6.875rem;
    height: 6.875rem;
}

.middle_title {
    margin-top: 1.25rem;
}

.content_title {
    margin-top: 1rem;
    color: #626466;
}

.symptom_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    margin-top: 2rem;
    gap: 1rem;
}

.symptom_button {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: 0 1.5rem;
    gap: 1.25rem;
    border-radius: 0.625rem;
    outline: 0.0625rem solid var(--For-fonts-4, #d2d5d9);
    background: var(--For-fonts-white, #fff);
}
