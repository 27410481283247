.program_picker {
    position: absolute;
    top: 3.5rem;
    left: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem 0rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.program_picker .item_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1.5rem;
    gap: 1.75rem;
}

.program_picker .item_container .program_button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.program_picker .button_container {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
    width: 100%;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem 0 0;
}

.program_picker .button_container .cancel_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.875rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
}

.program_picker .button_container .save_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.875rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}
