.modal_main_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    z-index: 10;
    width: 37.625rem;
    /* top: 5.3125rem; */
    /* right: 17.5rem; 괜찮으면 바꾸기*/
    top: 3.5rem;
    right: calc(100% - 1.5rem);
    /* right: 22rem; */
    border-radius: 1.25rem;
    border: 0.0625rem solid var(--primitive-grey-300);
    background: var(--white);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
}

.title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    /* border-radius: 0.9375rem 0.9375rem 0 0;
    border-width: 0.0625rem 0.0625rem 0 0.0625rem;
    border-style: solid;
    border-color: #e3e5ea; */
}

.content_container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.5rem;
    /* border: 0.0625rem solid #e3e5ea; */
    border-top: 0.0625rem solid var(--primitive-grey-300);
    background: var(--primitive-grey-100);
}

.menu_container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.menu_button {
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
}

.menu_button.activate {
    color: #fff;
    background: var(--primitive-blue-600);
}

.menu_button.deactivate {
    border: 0.0625rem solid var(--primitive-grey-300);
    color: #9d9fa2;
    background: #fff;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.content_item {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1.5rem;
    gap: 1rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--primitive-grey-300);
    background: #fff;
}

.content_item_title_container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.content_item_pushtype {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 2rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.3125rem;
}

.approval_refusal_container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.75rem;
}

.approval_refusal_button {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3rem;
    padding: 0.75rem 0rem;
    border-radius: 0.625rem;
    border: 1px solid var(--primitive-grey-300);
    background: var(--primitive-grey-100);
}

.refusal_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75rem 0;
    height: 3rem;
    border-radius: 0.625rem;
    border: 1px solid var(--primitive-grey-300);
    background: var(--white);
}

.approval_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75rem 0;
    height: 3rem;
    border-radius: 0.625rem;
    background: var(--primitive-blue-600);
}

.exp_date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5.75rem;
    color: #626466;
}
