.modal_main_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    z-index: 10;
    box-sizing: border-box;
    width: 11rem;
    top: 5.31rem;
    right: 11.8rem;
    padding: 1.25rem 1.5rem;
    border-radius: 0.625rem;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.5rem;
    margin: 1.25rem 0;
    background-color: red;
}

.button_content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.button.activate {
    color: #3a5074;
}

.button.deactivate {
    color: #020202;
}
