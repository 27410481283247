.modal_content_container {
    /* position: fixed;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 58.13%;
    height: 100%;
    padding: 2.25rem 2.25rem 3.75rem 2.25rem;
    top: 50%;
    right: 1.2rem;
    transform: translateY(-50%);
    background-color: #fff; */
    /* position: fixed; */
    /* padding: 2.25rem 2.25rem 3.75rem 2.25rem; */
    /* padding-block: 2.25rem; */
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.content_container {
    display: flex;
    flex-direction: column;
    gap: 2.5;
    max-height: 85dvh;
    overflow-y: auto;
}

.content_container .payment_info_container {
    display: flex;
    flex-direction: column;
    padding: 1.75rem;
    gap: 2.5rem;
    border-radius: 1.25rem;
    border: 1px solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.content_container .payment_info_container .payment_info {
    display: flex;
    flex-direction: column;
}

.content_container .payment_info_container .payment_info .middle_title {
    margin-bottom: 1.25rem;
}

.content_container .payment_info_container .payment_info .item_container {
    display: flex;
    gap: 2.5rem;
}
.content_container
    .payment_info_container
    .payment_info
    .item_container
    .payStatus_container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.content_container
    .payment_info_container
    .payment_info
    .item_container
    .item_title {
    width: 23.4375rem;
    margin-bottom: 0.75rem;
}

.item_pay_status_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    padding: 0 0.5rem;
    border-radius: 0.3125rem;
}

.item_pay_status_item.activate {
    color: #39d71d;
    background: #e4ffdf;
}

.item_pay_status_item.deactivate {
    color: #626466;
    background: var(--For-fonts-6, #eef0f3);
}

.payment_cancel_container {
    display: flex;
    width: 100%;
    margin-top: 2.5rem;
    justify-content: center;
}

.payment_cancel_button {
    width: 23.75rem;
    height: 4rem;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}

.payment_check_button {
    width: 23.75rem;
    height: 4rem;
    border-radius: 0.625rem;
    color: #3a5074;
    border: 1px solid var(--primary-01-navy-1, #3a5074);
    background: var(--For-fonts-white, #fff);
}

.payment_history_container {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    gap: 2.5rem;
}

.payment_history_container .content_container {
    display: flex;
    flex-direction: column;
}

.payment_history_container .content_container {
}

.payment_history_container .content_container .product_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.payment_history_container .content_container .product_content .modDate {
    display: flex;
    align-items: center;
    width: 10rem;
}

.payment_history_container .content_container .product_content .totalAmt {
    display: flex;
    align-items: center;
    width: 7.5rem;
}

.payment_history_container
    .content_container
    .product_content
    .product_container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
}

.icon_arrowright {
    width: 1rem;
    height: 1rem;
}
