.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2.5rem;
    padding: 2.3125rem 2.0625rem;
}

.content_container {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
}

.program_status_container {
    display: flex;
    align-items: center;
    padding: 2.3125rem 2.25rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
}

.program_status {
    border-radius: 0.3125rem;
}

.program_status.activate {
    padding: 0.25rem 0.5rem;
    color: #39d71d;
    background: #e4ffdf;
}

.program_status.deactivate {
    padding: 0.25rem 0.5rem;
    color: #626466;
    background: var(--For-fonts-6, #eef0f3);
}

.progress_container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 2.25rem 2.5rem;
}

.progress_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.progress_status_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.5rem;
    height: 4rem;
    border-radius: 0.625rem;
    color: #3a5074;
    border: 0.0625rem solid var(--primary-01-navy-1, #3a5074);
    background: var(--For-fonts-white, #fff);
}

.progress_button_container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.progress_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
}

.progress_button.activate {
    color: #3a5074;
    border: none;
    background-color: #e9f0fd;
}

.progress_button.deactivate {
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    color: #9d9fa2;
}

.progress_button.finsh_activate {
    color: #9d9fa2;
    background: var(--For-fonts-7, #f6f7f9);
}

.title_container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.75rem;
}

.title {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 100px;
}

.title.activate {
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}

.title.deactivate {
    color: #fff;
    background: var(--For-fonts-3, #9d9fa2);
}
