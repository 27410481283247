.schedule_more {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    z-index: 10;
    width: 24.125rem;
    padding: 1.25rem 1.5rem;
    gap: 1rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.schedule_more .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.schedule_more .content_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
