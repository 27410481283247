.time_picker {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    overflow-y: auto;
    top: 3.5rem;
    width: 43.7%;
    height: 19.125rem;
    padding: 1.5rem 1.5rem 1.25rem 1.5rem;
    gap: 1.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.position_left {
    left: 0;
}

.position_right {
    right: 0;
}

.time_picker .button {
    display: flex;
    width: 100%;
}

.time_picker .button .title {
    white-space: nowrap;
}
