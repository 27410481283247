.main_container {
    display: flex;
    height: 100vh;
    justify-content: space-between;
}

.view_container {
    display: flex;
    width: 63.96%;
    justify-content: center;
    padding: 7.5rem 0 4.625rem 0;
}
.content_container {
    display: block;
    width: 31.75rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.logo {
    width: 3.75rem;
    height: 3.75rem;
}

.login_title {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.input_content_container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
}

.input_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2.5rem;
    gap: 1.75rem;
}
.icon_arrowbottm {
    position: absolute;
    top: 1.5rem;
    right: 1.25rem;
}
.input_phone_number {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    width: 100%;
}

.find_success_id_container {
    display: flex;
    flex-direction: column;
    margin-top: 1.75rem;
    gap: 1rem;
}

.find_success_id {
    display: flex;
    box-sizing: border-box;
    height: 5.25rem;
    padding: 1.18rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.625rem;
    background: #f6f7f9;
}
