.modal_content_container {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 2rem 2.5rem 2rem 2.5rem;
    width: 40.81rem;
    gap: 2rem;
    top: 50%;
    left: 50%;
    border-radius: 1.25rem;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

.button_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.75rem;
}

.title {
    margin-bottom: 2.5rem;
}

.first_button {
    width: 4.56rem;
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    border-radius: 0.625rem;
    box-sizing: border-box;
    color: #fff;
    background: #3a5074;
}

.second_button {
    /* width: 4.56rem; */
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    border-radius: 0.625rem;
    box-sizing: border-box;
    color: #020202;
    background: #fff;
}
