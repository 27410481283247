.calendar_container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    height: 100%;
}

.calendar_container .calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.calendar_container .calendar .event {
    /* display: flex; */
    /*flex-direction: column; /* 사파리에서 text-align: left 적용 안되서 제거 */
    align-items: center;
    box-sizing: border-box;
    text-align: left;
    padding: 0.38rem 0.62rem;
    margin-bottom: 0.25rem;
    height: auto; /* 높이를 자동으로 조정 */
    width: 100%;
    border-radius: 0.3125rem;
    color: #fff; /* 텍스트 색상 */
    z-index: 1; /* 기본 z-index */
    position: relative; /* 위치 조정 */
}

.calendar_container .calendar .event .title {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
}

.calendar_container .calendar .event .time {
    font-size: 0.875rem; /* 시간 표시 폰트 크기 */
    color: #fff;
}

.calendar_container .calendar .calendar_header {
    display: flex;
    width: 100%;
    align-items: center;
}

.calendar_container .calendar .calendar_header .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2.25rem 2.25rem 1.25rem 2.25rem;
}
.calendar_container .calendar .calendar_header .left_menu_container {
    display: flex;
    align-items: center;
    gap: 1.75rem;
}

.calendar_container .calendar .calendar_header .today_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 3rem;
    padding: 0 1.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    color: #626466;
    background: var(--For-fonts-white, #fff);
}
.calendar_container .calendar .calendar_header .button_container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}
.calendar_container .calendar .calendar_header .prev_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}
.calendar_container .calendar .calendar_header .next_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.calendar_container .calendar .calendar_header .timeframe {
    display: flex;
    padding: 0.19rem;
    border-radius: 0.625rem;
    background: var(--For-fonts-6, #eef0f3);
}

.calendar_container .calendar .calendar_header .timefram_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.5625rem;
    height: 2.635rem;
}

.calendar_container
    .calendar
    .calendar_header
    .navigation
    .timefram_button.activate {
    border-radius: 0.5rem;
    color: #3a5074;
    background: var(--For-fonts-white, #fff);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.15);
}
.calendar_container
    .calendar
    .calendar_header
    .navigation
    .timefram_button.deactivate {
    color: #9d9fa2;
}

.calendar_container .calendar .month_weekdays {
    display: grid;
    align-items: center;
    width: 100%;
    height: 2.75rem;
    grid-template-columns: repeat(7, 1fr);
    background: var(--For-fonts-7, #f6f7f9);
}

.calendar_container .calendar .month_weekdays .weekday {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #626466;
}

.calendar_container .calendar .days {
    display: grid;
    width: 100%;
    height: 87.15%;
    grid-template-columns: repeat(7, 1fr);
}

.calendar_container .calendar .days .day {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 12.5rem;
    padding: 0.75rem 0.38rem;
    border-style: solid;
    border-color: var(--For-fonts-5, #e3e5ea);
}

.calendar_container .calendar .day_child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
}

.calendar_container .calendar .today_child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    background: var(--primary-01-navy-1, #3a5074);
}

.calendar_container .calendar .event {
    /* display: flex; */
    align-items: center;
    box-sizing: border-box;
    text-align: left;
    padding: 0.38rem 0.62rem;
    margin-bottom: 0.25rem;
    height: 1.875rem;
    width: 100%;
    border-radius: 0.3125rem;
}
.calendar_container .calendar .day .schedule_button_container {
    position: relative;
    z-index: 5;
    width: 100%;
}

.calendar_container .calendar .day .more_schedule_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0.38rem 0.62rem;
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--primary-01-navy-4, #a9bcdb);
    background: var(--primary-01-navy-7, #f6f9ff);
}

.calendar_container .calendar .day .more_schedule_button .icon_arrowright {
    width: 0.5rem;
    height: 0.5rem;
    color: #3a5074;
}

/* .calendar_container .calendar .event .title {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
} */

/* 주간 */
.calendar_container .calendar .weekdays {
    display: grid;
    align-items: center;
    box-sizing: border-box;
    padding: 0.82rem 0 0.63rem 4.7%;
    grid-template-columns: repeat(7, 1fr);
    background: var(--For-fonts-7, #f6f7f9);
}

.calendar_container .calendar .weekdays .weekday {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0.81rem;
    color: #626466;
}

.icon_arrowright {
    width: 1rem;
    height: 1rem;
}
.icon_arrowleft {
    width: 1rem;
    height: 1rem;
}
