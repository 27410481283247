.footer_container {
    display: flex;
    flex-direction: column;
    padding-left: 18.25rem;
    padding-right: 2rem;
    padding-bottom: 3.75rem;
    gap: 1.5rem;
}

.policy_container {
    display: flex;
    align-items: center;
    padding: 1.5rem 0;
    gap: 0.75rem;
    border-width: 0.0625rem 0;
    border-style: solid;
    border-color: #e3e5ea;
}

.policy_content_container {
    display: flex;
    align-items: flex-start;
    gap: 6.88rem;
}

.policy_content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.registration_number {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.length_line {
    width: 0.125rem;
    height: 1rem;
    background: #e3e5ea;
}

.icon_header_logo {
    width: 8.75rem;
    height: 1.625rem;
}

.line {
    display: flex;
    width: 0.0625rem;
    height: 0.75rem;
    background: var(--For-fonts-5, #e3e5ea);
}
