.profile_container {
    display: flex;
    gap: 1.25rem;
    border-radius: 1.25rem;
    padding: 2.25rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
}

.profile_content_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile_content {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #e3e5ea;
}
.profile_chart_number {
    display: inline-block;
    box-sizing: border-box;
    padding: 0.25rem 0.5rem;
    margin-top: 1rem;
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-7, #f6f7f9);
}

.profile_join_date {
    margin-top: 0.25rem;
}

.current_program_container {
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #e3e5ea;
}

.current_program {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    /* gap: 0.5rem; */
    color: #626466;
}

.counsel_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5rem;
    color: #626466;
}

.dot_menu_container {
    display: flex;
    align-items: center;
    position: relative;
    gap: 1.4rem;
}

.menu_container {
    position: absolute;
    top: 3rem;
    right: -0.75rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    padding: 1.25rem 1.5rem;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.change_therapist_button {
    display: flex;
    box-sizing: border-box;
    height: 2.5rem;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--primary-01-navy-1, #3a5074);
    color: #3a5074;
    background: var(--For-fonts-white, #fff);
}

.move_to_workbook_button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    box-sizing: border-box;
    height: 2.5rem;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--primary-01-navy-4, #a9bcdb);
    background: var(--primary-01-navy-7, #f6f9ff);
    color: #3a5074;
}

.memo_container {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
    gap: 1rem;
}

.memo {
    box-sizing: border-box;
    height: 10.635rem;
    padding: 1.25rem;
    border-radius: 0.9375rem;
    border: 1px solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.memo_button_container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.memo_cancel_button {
    padding: 0.75rem 1.5rem;
}

.memo_save_button {
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}

.icon_session {
    width: 1rem;
    height: 1rem;
    color: #3a5074;
}

.icon_dot_menu {
    width: 1.5rem;
    height: 1.5rem;
}

.menu_button {
    display: flex;
    width: 6.25rem;
}
