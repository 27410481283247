.situation_emotion_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.74rem 3.25rem 3.75rem 3.25rem;
}

.situation_container {
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 6.31rem;
    gap: 1.25rem;
}

.situation_container .situation_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 1.25rem;
}

.situation_container .situation_content_container .situation_input_contaienr {
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
}

.situation_container
    .situation_content_container
    .situation_input_contaienr
    .situation_content {
    min-width: 8.06rem;
    margin-top: 0.7rem;
    white-space: nowrap;
    color: #626466;
}

.situation_container
    .situation_content_container
    .situation_input_contaienr
    .situation_input_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.situation_container
    .situation_content_container
    .situation_input_contaienr
    .situation_input {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0.625rem;
    padding: 1.19rem 1.25rem;
    resize: vertical;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.situation_emotion_container .situation_emotion {
    display: flex;
    width: 6.5rem;
    height: 14.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    color: #fff;
    background: #41465a;
}

.emotion_container {
    display: flex;
    width: 100%;
    gap: 1.25rem;
    margin-top: 4.81rem;
}

.emotion_container .emotion_content_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.emotion_container .emotion_content_container .title_score_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.situation_emotion_container .select_score_radio_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    gap: 3.25rem;
    padding-left: 7.75rem;
    margin-top: 3.75rem;
}

.situation_emotion_container
    .select_score_radio_container
    .radio_item_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.situation_emotion_container
    .select_score_radio_container
    .radio_item_container
    .radio_item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.score_container {
    position: relative;
    display: flex;
    align-items: center;
}
.score_input {
    box-sizing: border-box;
    width: 13.125rem;
    height: 4rem;
    padding: 0.6875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.emotion_icon_container_box {
    display: flex;
    height: 11rem;
    align-items: center;
    box-sizing: border-box;
    gap: 0.75rem;
}

.emotion_icon_container.deactivate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 1rem;
    gap: 1.25rem;
    opacity: 0.5;
}
.emotion_icon_container.activate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 1rem;
    gap: 1.25rem;
    border-radius: 0.625rem;
    border: 0.1875rem solid #41465a;
    background: #f2f4fc;
}

.icon_container {
    display: flex;
    align-items: center;
    height: 5.81rem;
}

.thought_situation {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    /* padding: 2.5rem 3.25rem 13.75rem 3.25rem; */
    padding: 2.5rem 3.25rem;
}

.thought_situation_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.thought_situation_input {
    box-sizing: border-box;
    width: 100%;
    /* height: 20.375rem; */
    padding: 1.25rem;
    border-radius: 1.25rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.bottom_five_seven_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* padding: 2.5rem 3.25rem 17.88rem 3.25rem; */
    padding: 2.5rem 3.25rem;
}

.img_to_mind {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 3.75rem;
    border-bottom: 2px dashed #6b7393;
    gap: 2rem;
}

.mind {
}

.my_appearance {
}

.circle_question_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.75rem;
    padding: 0.88rem 0;
}

.circle_question {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.circle_title {
    white-space: nowrap;
    width: 10.375rem;
    color: #626466;
}

.icon_same_container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 10.625rem;
}

.circle_input_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.circle_input {
    box-sizing: border-box;
    width: 100%;
    /* height: 10.625rem; */
    padding: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.percent_input_container {
    position: relative;
    display: flex;
    align-items: center;
}

.percent_input {
    max-width: 13.125rem;
    box-sizing: border-box;
    height: 4rem;
    padding: 0.6875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

/* 환자 퀴즈 */
.quiz_container {
    display: flex;
    position: relative;
    width: 100%;
}

.quiz_list {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 49.37%;
    left: 25.25%;
    bottom: 8.52%;
}

.quiz_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 2.59% 1.75%;
    width: 100%;
    height: 8.15%;
    gap: 1rem;
}

.quiz_button.activate {
    border-radius: 0.625rem;
    border: 0.1875rem solid #41465a;
    background: #f2f4fc;
}

/* 치료자 퀴즈 */

.quiz_container.therapist {
    display: flex;
    position: relative;
    width: 100%;
}

.quiz_list.therapist {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 49.37%;
    left: 25.25%;
    bottom: 7.41%;
}

.quiz_button.therapist {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 2.1%;
    width: 100%;
    height: 8.15%;
    gap: 1rem;
}

.quiz_button.activate {
    border-radius: 0.625rem;
    border: 0.1875rem solid #41465a;
    background: #f2f4fc;
}

.degree_trust {
    display: flex;
    /* align-items: center; */
    margin-top: 3.75rem;
    gap: 11.31rem;
}

.cognitive_error {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    gap: 2.25rem;
    padding: 5rem 3.25rem 18.875rem 3.25rem;
    background: #f7f7f7;
}

.cognitive_content_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.cognitive_button {
    display: flex;
    align-items: flex-start;
    text-align: left;
    box-sizing: border-box;
    padding: 4.46% 1.88%;
    gap: 1rem;
}
.cognitive_button.activate {
    border-radius: 0.625rem;
    outline: 0.1875rem solid #41465a;
    background: #f2f4fc;
}

.cognitive_text_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* 소크라테스 문답법 */
.socratic_question_answer {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 3.75rem;
    padding: 3.75rem 3.25rem 6.5rem 3.25rem;
    gap: 2.5rem;
}

.socratic_question_answer .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3.75rem;
}

.socratic_question_answer .content .automatic_accident {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.socratic_question_answer .content .input {
    box-sizing: border-box;
    /* height: 4rem; */
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.socratic_question_answer .content .evidence {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.socratic_question_answer .content .evidence .evidence_container {
    display: flex;
    gap: 2rem;
    width: 100%;
}
.socratic_question_answer .content .evidence .evidence_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1.26rem;
}

/* 소크라테스 문답법 step */

.socratic_question_answer_step {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-top: 3.75rem;
    padding: 3.75rem 3.25rem 3.75rem 3.25rem;
    gap: 2.5rem;
}

.socratic_question_answer_step .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3.75rem;
}

.socratic_question_answer_step .content .automatic_accident {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.socratic_question_answer_step .content .step_container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.socratic_question_answer_step
    .content
    .step_container
    .mult_textarea_container {
    display: flex;

    gap: 0.75rem;
}

.socratic_question_answer_step
    .content
    .step_container
    .mult_textarea_container
    .mult_textarea {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
}

.socratic_question_answer_step
    .content
    .step_container
    .mult_textarea_container
    .textarea {
    display: flex;
    box-sizing: border-box;
    padding: 1.25rem;
    /* height: 4.125rem; */
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}
.socratic_question_answer_step .content .step_container .textarea {
    display: flex;
    box-sizing: border-box;
    padding: 1.25rem;
    /* height: 10.5625rem; */
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.socratic_question_answer_step .content .input {
    box-sizing: border-box;
    /* height: 4rem; */
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.socratic_question_answer_step .content .evidence {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.socratic_question_answer_step .content .evidence .evidence_container {
    display: flex;
    gap: 2rem;
    width: 100%;
}
.socratic_question_answer_step .content .evidence .evidence_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1.26rem;
}

.evidence_input_conatiner {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.evidence_input {
    box-sizing: border-box;
    width: 100%;
    /* height: 26.875rem; */
    padding: 1.25rem;
    border-radius: 1.25rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.realict_results {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 3.25rem 3.75rem 3.25rem;
    gap: 2.5rem;
}

.realict_results .content_container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.realict_results .input_container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.realict_results .input {
    box-sizing: border-box;
    /* height: 10.5625rem; */
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}
.realict_results .img {
    width: 100%;
    background-size: contain;
}

.advantage_automatic_accidents {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 3.25rem 3.75rem 3.25rem;
    gap: 3.75rem;
}

.advantage_automatic_accidents .step_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
}
.advantage_automatic_accidents .step_mult_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.advantage_automatic_accidents .step_container .textarea {
    display: flex;
    box-sizing: border-box;
    height: 4rem;
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-7, #f6f7f9);
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 2.5rem;
    width: 5.0625rem;
    border-radius: 0.4375rem;
    color: #fff;
    background: var(--primary-01-navy-2, #61789e);
}

.advantage_automatic_accidents .input_container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.advantage_automatic_accidents .input {
    box-sizing: border-box;
    /* height: 10.5625rem; */
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.advantage_automatic_accidents .letter_feedback_container {
    display: flex;
    width: 100%;
}

.advantage_automatic_accidents .letter_container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 49.4%;
}
.advantage_automatic_accidents .letter_container .img {
    width: 100%;
    background-size: contain;
}
.advantage_automatic_accidents .letter_container .input_container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
}
.advantage_automatic_accidents .letter_container .input {
    position: absolute;
    left: 7.32%;
    top: calc(3rem + 0.75rem);
    /* top: 23%; */
    /* top: 50%; */
    /* transform: translateY(-50%); */
    /* bottom: 14.64%; */
    /* height: 63.17%; */
    width: 85.21%;
    padding: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.automatic_accident_inspection {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 3.75rem 3.25rem;
    gap: 2.5rem;
}

.automatic_accident_inspection .automatic_think_content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
}

.automatic_accident_inspection .automatic_think_content .textarea {
    display: flex;
    box-sizing: border-box;
    /* height: 4rem; */
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-7, #f6f7f9);
}

.automatic_accident_inspection .rational_think_content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.84rem;
}

.automatic_accident_inspection .rational_think_content .input {
    box-sizing: border-box;
    /* height: 13.875rem; */
    padding: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.automatic_accident_inspection .emotional_score_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.automatic_accident_inspection .emotional_score {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.automatic_accident_inspection .emotional_score .score_container {
    position: relative;
    display: flex;
    align-items: center;
}
.automatic_accident_inspection .emotional_score .score_input {
    box-sizing: border-box;
    width: 13.125rem;
    height: 4rem;
    padding: 0.6875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.automatic_accident_inspection .emotional_score .emotion_icon_container_box {
    display: flex;
    height: 11rem;
    align-items: center;
    box-sizing: border-box;
    gap: 0.75rem;
}

.automatic_accident_inspection .emotional_score .select_score_radio_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    gap: 3.25rem;
    margin-top: 3.75rem;
}

.automatic_accident_inspection
    .emotional_score
    .select_score_radio_container
    .radio_item_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.75rem;
}
.automatic_accident_inspection
    .emotional_score
    .select_score_radio_container
    .radio_item_container
    .radio_item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.emotion_icon_container.deactivate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 1rem;
    gap: 1.25rem;
    opacity: 0.5;
}
.emotion_icon_container.activate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    padding: 1rem;
    gap: 1.25rem;
    border-radius: 0.625rem;
    /* outline: 3px solid #41465a; */
    background: #f2f4fc;
}

.icon_container {
    display: flex;
    align-items: center;
    height: 5.81rem;
}

/* 자동사고 그래프 */
.possibilities_automatic_accident {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 3.25rem 3.75rem 3.25rem;
}

.possibilities_automatic_accident .step {
    height: 2.5rem;
    padding: 0.4375rem;
    margin-bottom: 1.25rem;
    border-radius: 0.4375rem;
    background: var(--primary-01-navy-2, #61789e);
}

.possibilities_automatic_accident .step_container .textarea {
    box-sizing: border-box;
    /* height: 10.5625rem; */
    padding: 1.1875rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.possibilities_automatic_accident .content_container {
    display: flex;
    box-sizing: border-box;
    margin-top: 1.5rem;
    gap: 6.75rem;
}

.img {
    width: 100%;
    background-size: contain;
}

.possibilities_automatic_accident .input_feedback_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
}

.possibilities_automatic_accident .input_feedback_container.padding_bottom {
    padding-bottom: 1.25rem;
}

.possibilities_automatic_accident .input_container {
    display: flex;
    align-items: center;
    resize: vertical;
    gap: 0.75rem;
}

.possibilities_automatic_accident .input_title {
    min-width: 2.875rem;
    box-sizing: border-box;
    padding: 1.06rem 0.81rem;
    border-radius: 0.625rem;
    color: #fff;
    background: #41465a;
}

.possibilities_automatic_accident .input {
    /* display: flex; */
    width: 100%;
    box-sizing: border-box;
    /* height: 4rem; */
    padding: 1.1875rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.learned_felt {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2.5rem 3.25rem 0 3.25rem;
    gap: 3.75rem;
}

.learned_felt .input_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.learned_felt .input {
    box-sizing: border-box;
    /* height: 13.875rem; */
    padding: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.postit_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.postit {
    width: 14.9375rem;
    height: 2.86831rem;
}

.postit_text {
    position: absolute;
    color: #fff;
}

.socratic_question_answer_explanation {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.guide_container {
    position: fixed;
    top: 8.125rem;
    /* left: 2rem; */
    left: 3rem;
    width: 30rem;
    z-index: 129;
}

.guide_container .button_container {
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    margin-left: 16.25rem;
    width: 14.75rem;
    height: 3.875rem;
    padding-left: 1.25rem;
    border-radius: 7.5rem;
    border: 0.0635rem solid #e3e5ea;
    background: var(--For-fonts-white, #fff);
    box-shadow: 0 0.6rem 1.5rem 0 rgba(0, 0, 0, 0.15);
}

.guide_container .button_container.therapist {
    margin-left: 32.5rem;
}

.guide_img_container {
    display: flex;
    flex-grow: 1;
    position: relative;
    gap: 1.74rem;
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    /* box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.25); */
}

.guide_container .button_container .button {
    position: absolute;
    width: 3.875rem;
    height: 3.875rem;
    border-radius: 1.25rem;
    right: -0.2rem;
    background: var(--For-fonts-white, #fff);
    box-shadow: 0 0.6rem 1.5rem 0 rgba(0, 0, 0, 0.15);
    z-index: 20;
}

.guide_container .button_container .button .icon_x {
    width: 2rem;
    height: 2rem;
    color: #626466;
}

.submit_button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 23.75rem;
    height: 4rem;
    border-radius: 0.625rem;
    color: #fff;
}

.submit_button.activate {
    background: var(--primary-01-navy-1, #3a5074);
}

.submit_button.decativate {
    background: var(--For-fonts-5, #e3e5ea);
}

.pdf_download_button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 23.75rem;
    gap: 0.5rem;
    height: 4rem;
    border-radius: 0.625rem;
    border: 1px solid var(--primary-01-navy-1, #3a5074);
    background: var(--For-fonts-white, #fff);
}

.icon_emotion_bubble {
    position: absolute;
    top: -3.8125rem;
    width: 16.0625rem;
    height: 2.5625rem;
}

.icon_automatic_accident_bubble {
    position: absolute;
    right: 0;
    /* top: -50%; */
    top: -1.25rem;
    transform: translateY(-100%);
    width: 6.25rem;
    height: 4.25rem;
}

.icon_image {
    width: 1.82881rem;
    height: 1.5625rem;
    margin-left: 0.75rem;
}

.icon_human {
    width: 1.875rem;
    height: 1.875rem;
    margin-left: 0.75rem;
}

.icon_pencil {
    width: 1.875rem;
    height: 1.875rem;
}

.icon_change_automatic_accident_bubble {
    position: absolute;
    top: -2%;
    right: 0;
    width: 16.0625rem;
    height: 2.5625rem;
}

.icon_plus {
    width: 1.5rem;
    height: 1.5rem;
}

.icon_same {
    width: 3.125rem;
    height: 3.125rem;
}
