/* 폰트 적용코드 */

// @font-face {
//     font-family: 'Jalnan2-Regular';
//     src: url('Jalnan2TTF.woff2') format('woff2'), url('Jalnan2TTF.woff') format('woff');
//     font-weight: 400;
//     font-style: normal;
//     font-display: swap;
// }

// @function resize($px) {
//     @return #{calc(var(--dynamic-width) * $px)};
// }
$NotoSansKR: 'Noto Sans KR', sans-serif;
$Inter: 'Inter', sans-serif;

@function resize($px) {
    @return #{calc($px / 16)}rem;
}
/* * --------------------- 헤더 시작 --------------------- * */

/* 국문 */
.text_40_NotoSansKR_Bold {
    font-size: 2.5rem;
    font-family: $NotoSansKR;
    font-weight: 700;
    color: #020202;
    line-height: 3.5rem;
    letter-spacing: calc(2.5rem * (-1.5 / 100));
}
.text_32_NotoSansKR_Bold {
    font-size: 2rem;
    font-family: $NotoSansKR;
    font-weight: 700;
    color: #020202;
    line-height: 2.5rem;
    letter-spacing: calc(2rem * (-2 / 100));
}
.text_28_NotoSansKR_Bold {
    font-size: 1.75rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 2.25rem;
    letter-spacing: calc(1.75rem * (-2 / 100));
}
.text_24_NotoSansKR_Bold {
    font-size: 1.5rem;
    font-family: $NotoSansKR;
    font-weight: 700;
    color: #020202;
    line-height: 2rem;
    letter-spacing: calc(1.5rem * (-2.5 / 100));
}
.text_22_NotoSansKR_Bold {
    font-size: 1.375rem;
    font-family: $NotoSansKR;
    font-weight: 700;
    color: #020202;
    line-height: 1.875rem;
    letter-spacing: calc(1.375rem * (-3 / 100));
}
.text_18_NotoSansKR_Bold {
    font-size: 1.125rem;
    font-family: $NotoSansKR;
    font-weight: 700;
    color: #020202;
    line-height: 1.625rem;
    letter-spacing: calc(1.125rem * (-4 / 100));
}

/* 영문 */
.text_40_Inter_Bold {
    font-size: 2.5rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 3.5rem;
}
.text_32_Inter_Bold {
    font-size: 2rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 2.5rem;
}
.text_28_Inter_Bold {
    font-size: 1.75rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 2.25rem;
}

.text_24_Inter_Bold {
    font-size: 1.5rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 2rem;
}
.text_22_Inter_Bold {
    font-size: 1.375rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 1.875rem;
}
.text_18_Inter_Bold {
    font-size: 1.125rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 1.625rem;
}
/* * --------------------- 헤더 끝 --------------------- * */

/* * --------------------- 서브타이틀 시작 --------------------- * */

/* 국문 */
.text_22_NotoSansKR_Medium {
    font-size: 1.375rem;
    font-family: $NotoSansKR;
    font-weight: 500;
    color: #020202;
    line-height: 1.875rem;
    letter-spacing: calc(1.375rem * (-3 / 100));
}
.text_18_NotoSansKR_Regular {
    font-size: 1.125rem;
    font-family: $NotoSansKR;
    font-weight: 400;
    color: #020202;
    line-height: 1.625rem;
    letter-spacing: calc(1rem * (-4 / 100));
}
.text_14_NotoSansKR_Bold {
    font-size: 0.875rem;
    font-family: $NotoSansKR;
    font-weight: 700;
    color: #020202;
    line-height: 1.25rem;
    letter-spacing: calc(0.875rem * (-5 / 100));
}

/* 영문 */
.text_18_Inter_Regular {
    font-size: 1.125rem;
    font-family: $NotoSansKR;
    font-weight: 400;
    color: #020202;
    line-height: 1.625rem;
}
.text_16_Inter_SemiBold {
    font-size: 1rem;
    font-family: $Inter;
    font-weight: 600;
    color: #020202;
    line-height: 1.5rem;
}
.text_14_Inter_Bold {
    font-size: 0.875rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 1.25rem;
}
.text_14_Inter_SemiBold {
    font-size: 0.875rem;
    font-family: $Inter;
    font-weight: 600;
    color: #020202;
    line-height: 1.25rem;
}

/* * --------------------- 서브타이틀 끝 --------------------- * */

/* * --------------------- 버튼 시작 --------------------- * */

/* 국문 */
.text_24_NotoSansKR_Medium {
    font-size: 1.5rem;
    font-family: $Inter;
    font-weight: 500;
    color: #020202;
    line-height: 2rem;
    letter-spacing: calc(1.5rem * (-2.5 / 100));
}
.text_18_NotoSansKR_Medium {
    font-size: 1.125rem;
    font-family: $NotoSansKR;
    font-weight: 500;
    color: #020202;
    line-height: 1.5rem;
    letter-spacing: calc(1.125rem * (-4 / 100));
}
.text_16_NotoSansKR_Bold {
    font-size: 1rem;
    font-family: $NotoSansKR;
    font-weight: 700;
    color: #020202;
    line-height: 1.5rem;
    letter-spacing: calc(1rem * (-4 / 100));
}
.text_14_NotoSansKR_Medium {
    font-size: 0.875rem;
    font-family: $NotoSansKR;
    font-weight: 500;
    color: #020202;
    line-height: 1.25rem;
    letter-spacing: calc(0.875rem * (-5 / 100));
}

/* 영문 */
.text_24_Inter_Medium {
    font-size: 1.5rem;
    font-family: $Inter;
    font-weight: 500;
    color: #020202;
    line-height: 2rem;
}
.text_18_Inter_Medium {
    font-size: 1.125rem;
    font-family: $Inter;
    font-weight: 500;
    color: #020202;
    line-height: 1.5rem;
    letter-spacing: calc(1.125rem * (-4 / 100));
}
.text_16_Inter_Bold {
    font-size: 1rem;
    font-family: $Inter;
    font-weight: 700;
    color: #020202;
    line-height: 1.5rem;
}
.text_14_Inter_Medium {
    font-size: 0.875rem;
    font-family: $Inter;
    font-weight: 500;
    color: #020202;
    line-height: 1.25rem;
}

/* * --------------------- 버튼 끝 --------------------- * */

/* * --------------------- 바디 시작 --------------------- * */

/* 국문 */
.text_16_NotoSansKR_Medium {
    font-size: 1rem;
    font-family: $NotoSansKR;
    font-weight: 500;
    color: #020202;
    line-height: 1.5rem;
    letter-spacing: calc(1rem * (-4.5 / 100));
}
.text_16_NotoSansKR_Regular {
    font-size: 1rem;
    font-family: $NotoSansKR;
    font-weight: 400;
    color: #020202;
    line-height: 1.5rem;
    letter-spacing: calc(1rem * (-4 / 100));
}
.text_14_NotoSansKR_Regular {
    font-size: 0.875rem;
    font-family: $NotoSansKR;
    font-weight: 400;
    color: #020202;
    line-height: 1.25rem;
    letter-spacing: calc(0.875rem * (-5 / 100));
}
.text_12_NotoSansKR_Medium {
    font-size: 0.75rem;
    font-family: $NotoSansKR;
    font-weight: 500;
    color: #020202;
    line-height: 1.125rem;
    letter-spacing: calc(0.75rem * (-2.5 / 100));
}

/* 영문 */

.text_16_Inter_Medium {
    font-size: 1rem;
    font-family: $Inter;
    font-weight: 500;
    color: #020202;
    line-height: 1.5rem;
}
.text_16_Inter_Regular {
    font-size: 1rem;
    font-family: $Inter;
    font-weight: 400;
    color: #020202;
    line-height: 1.5rem;
}
.text_14_Inter_Regular {
    font-size: 0.875rem;
    font-family: $Inter;
    font-weight: 400;
    color: #020202;
    line-height: 1.25rem;
}
.text_12_Inter_Medium {
    font-size: 0.75rem;
    font-family: $Inter;
    font-weight: 500;
    color: #020202;
    line-height: 1.125rem;
}

/* * --------------------- 바디 끝 --------------------- * */

/* * --------------------- 캡션 시작 --------------------- * */
/* 국문 */
.text_12_NotoSansKR_SemiBold {
    font-size: 0.75rem;
    font-family: $NotoSansKR;
    font-weight: 600;
    color: #020202;
    line-height: 1rem;
    letter-spacing: calc(0.75rem * (-5.5 / 100));
}
.text_12_NotoSansKR_Regular {
    font-size: 0.75rem;
    font-family: $NotoSansKR;
    font-weight: 400;
    color: #020202;
    line-height: 1rem;
    letter-spacing: calc(0.75rem * (-5.5 / 100));
}
.text_10_NotoSansKR_SemiBold {
    font-size: 0.625rem;
    font-family: $NotoSansKR;
    font-weight: 600;
    color: #020202;
    line-height: 0.75rem;
    letter-spacing: calc(0.625rem * (-6 / 100));
}
.text_10_NotoSansKR_Medium {
    font-size: 0.625rem;
    font-family: $NotoSansKR;
    font-weight: 500;
    color: #020202;
    line-height: 0.75rem;
    letter-spacing: calc(0.625rem * (-6 / 100));
}
.text_10_NotoSansKR_Regular {
    font-size: 0.625rem;
    font-family: $NotoSansKR;
    font-weight: 400;
    color: #020202;
    line-height: 0.75rem;
    letter-spacing: calc(0.625rem * (-6 / 100));
}

/* 영문 */
.text_12_Inter_SemiBold {
    font-size: 0.75rem;
    font-family: $Inter;
    font-weight: 600;
    color: #020202;
    line-height: 1rem;
}
.text_12_Inter_Regular {
    font-size: 0.75rem;
    font-family: $Inter;
    font-weight: 400;
    color: #020202;
    line-height: 1rem;
}
.text_10_Inter_Medium {
    font-size: 0.625rem;
    font-family: $Inter;
    font-weight: 500;
    color: #020202;
    line-height: 0.75rem;
}
.text_10_Inter_Regular {
    font-size: 0.625rem;
    font-family: $Inter;
    font-weight: 400;
    color: #020202;
    line-height: 0.75rem;
}

/* * --------------------- 캡션 끝 --------------------- * */

/* * --------------------- event 시작 --------------------- * */
/* 국문 */
// .text_22_Jalnan_Regular {
//     color: '#fff';
//     text-align: center;
//     font-family: 'Jalnan2-Regular';
//     font-size: 1.375rem;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 1.375rem; /* 22) */
// }
// .text_18_NotoSansKR_SemiBold {
//     font-size: 1.125rem;
//     font-family: $NotoSansKR;
//     font-weight: 600;
//     color: #020202;
//     line-height: 1.625rem;
//     letter-spacing: calc(1.125rem * (-4 / 100));
// }
// .text_18_Inter_SemiBold {
//     font-size: 1.125rem;
//     font-family: $Inter;
//     font-weight: 600;
//     color: #020202;
//     line-height: 1.625rem;
//     letter-spacing: calc(1.125rem * (-4 / 100));
// }

/* * --------------------- event 끝 --------------------- * */
