.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.25rem 2rem;
    gap: 2.5rem;
}

.program_select_main_container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    padding: 2.25rem 22.5rem;
    gap: 2.5rem;
}

.content_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 2.25rem 2.25rem 5rem 2.25rem;
    gap: 5rem;
    border-radius: 1.25rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
}
