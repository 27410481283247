.search_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.25rem 2.25rem 1.25rem 2.25rem;
}

.search_container .input_container {
    position: relative;
    display: flex;
    align-items: center;
}

.search_container .input_container .input {
    display: flex;
    box-sizing: border-box;
    width: 27.125rem;
    height: 3rem;
    padding: 0 3rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.icon_search {
    position: absolute;
    left: 1.25rem;
}

.middle_title_container {
    display: flex;
    box-sizing: border-box;
    margin-top: 2.5rem;
    height: 4.125rem;
    padding-top: 1.25rem;
    gap: 2.5rem;
    border-bottom: 0.0625rem solid #e3e5ea;
}

.middle_title {
    display: flex;
}

.middle_title.activate {
    border-bottom: 4px solid #3a5074;
    color: #3a5074;
}

.middle_title.deactivate {
    color: #9d9fa2;
}

.search_container .filter_button_container {
    display: flex;
    padding: 0.19rem;
    border-radius: 0.625rem;
    background: var(--For-fonts-6, #eef0f3);
}

.search_container .filter_button_container .filter_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.5625rem;
    height: 2.635rem;
}

.search_container .filter_button_container .filter_button.activate {
    border-radius: 0.5rem;
    color: #3a5074;
    background: var(--For-fonts-white, #fff);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.15);
}

.search_container .filter_button_container .filter_button.deactivate {
    color: #9d9fa2;
}

.chart_title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.63rem 2.19rem;
    color: #626466;
    background: #f6f7f9;
}

.chart_title {
    width: 11rem;
    color: #626466;
}

.chart_content_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.06rem;
    padding: 0 2.19rem;
}

.chart_content_container.border_bottom {
    border-bottom: 0.0625rem solid #e3e5ea;
}

.chart_content_container .item {
    position: relative;
    width: 13.875rem;
}

.chart_content_container .item .dropdown_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem 1.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.dropdown_container {
    display: flex;
    position: absolute;
    flex-direction: column;
    box-sizing: border-box;
    top: 3rem;
    z-index: 10;
    width: 100%;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.dropdown_container .dropdown_item_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid #e3e5ea;
}

.dropdown_container .dropdown_item_container .dropdown_item_button {
    display: flex;
    text-align: left;
    gap: 0.75rem;
}

.dropdown_container .dropdown_button_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem 1.5rem 1.25rem 0;
}

.dropdown_container .dropdown_button_container .dropdown_cancel_button {
    display: flex;
    width: 5.875rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.dropdown_container .dropdown_button_container .dropdown_select_button {
    display: flex;
    width: 5.875rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.3125rem;
    background: var(--primary-01-navy-1, #3a5074);
}

.non_button_dropdown_container {
    display: flex;
    position: absolute;
    flex-direction: column;
    box-sizing: border-box;
    top: 3rem;
    z-index: 10;
    width: 100%;
    padding: 1.25rem 1.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.non_button_dropdown_container .employee_button_conatiner {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
}
.non_button_dropdown_container .role_button_conatiner {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.employee_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.icon_dot_menu {
    position: relative;
    width: 11rem;
    text-align: right;
}
