.modal_content_container {
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    width: 36.75rem;
    padding: 2rem 0 2rem 0;
    top: 50%;
    left: 50%;
    border-radius: 1.25rem;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

.title_header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5rem;
    margin-bottom: 2rem;
}
.middel_title_header_container {
    text-align: left;
    padding: 0 2.5rem;
}

.program_list_container {
    display: flex;
    align-items: center;
    padding: 0 2.5rem;
    margin-top: 3.25rem;
    gap: 2.5rem;
    border-bottom: 0.0625rem solid #E3E5EA;
}

.program_button {
    display: flex;
    padding-bottom: 1rem;
}

.program_button.activate {
    border-bottom: 0.25rem solid #3a5074;
}

.program_button.deactivate {
    padding-bottom: 1.25rem;
}

.therapist_list_container {
    display: flex;
    flex-direction: column;
    text-align: left;


}

.therapist_offical {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 2.5rem 0 2.5rem;
}

.therapist_content_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1.25rem 0;
    padding: 0 3.75rem;
    gap: 1.75rem;

}

.button_container {
    display: flex;
    justify-content: flex-end;
    padding: 0 2.5rem;
    margin-top: 2rem;
    gap: 0.75rem;
}

.cancel_button {
    box-sizing: border-box;
    height: 3rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
}

.save_button {
    box-sizing: border-box;
    height: 3rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
    color: #fff;
    background: var(--primary-01-navy-1, #3a5074);
}
