.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.content_container {
    display: flex;
    justify-content: center;
    padding-top: 5.875rem;
}

.content_container.activate {
    margin-left: 16.25rem;
}

.content_container.deactivate {
    width: 100%;
}
