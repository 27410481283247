.main_container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    max-width: 83.5rem;
    padding: 2.5rem 2rem 3.75rem 2rem;
}
.img1 {
    width: 100%;
    margin-top: 2.25rem;
    background-size: contain;
}

.img {
    width: 100%;
    background-size: contain;
}

.quiz_container {
    display: flex;
    position: relative;
    width: 100%;
}

.quiz_list {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 25.25%;
    bottom: 8.52%;
}

.quiz_button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 2.59% 1.75%;
    width: 49.25rem;
    height: 8.15%;
    gap: 1rem;
}

.quiz_button.activate {
    border-radius: 0.625rem;
    border: 0.1875rem solid #41465a;
    background: #f2f4fc;
}
