.search_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.25rem 2.25rem 1.25rem 2.25rem;
}

.search_container .input_container {
    position: relative;
    display: flex;
    align-items: center;
}

.search_container .input_container .input {
    display: flex;
    box-sizing: border-box;
    width: 27.125rem;
    height: 3rem;
    padding: 0 3rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.icon_search {
    position: absolute;
    left: 1.25rem;
}

.middle_title_container {
    display: flex;
    box-sizing: border-box;
    margin-top: 2.5rem;
    height: 4.12rem;
    padding-top: 1.25rem;
    gap: 2.5rem;
    border-bottom: 0.0625rem solid #e3e5ea;
}

.middle_title {
    display: flex;
}

.middle_title.activate {
    border-bottom: 4px solid #3a5074;
    color: #3a5074;
}

.middle_title.deactivate {
    color: #9d9fa2;
}

.search_container .filter_button_container {
    display: flex;
    padding: 0.19rem;
    border-radius: 0.625rem;
    background: var(--For-fonts-6, #eef0f3);
}

.search_container .filter_button_container .filter_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.5625rem;
    height: 2.635rem;
}

.search_container .filter_button_container .filter_button.activate {
    border-radius: 0.5rem;
    color: #3a5074;
    background: var(--For-fonts-white, #fff);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.15);
}

.search_container .filter_button_container .filter_button.deactivate {
    color: #9d9fa2;
}

.chart_title_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.63rem 2.19rem;
    color: #626466;
    background: #f6f7f9;
}

.chart_title {
    width: 11rem;
    color: #626466;
}

.chart_content_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.06rem;
    padding: 0 2.19rem;
}

.chart_content_container.border_bottom {
    border-bottom: 0.0625rem solid #e3e5ea;
}

.chart_content_container .item {
    position: relative;
    width: 13.875rem;
}

.item_pay_status_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border-radius: 0.3125rem;
}

.item_pay_status_item.activate {
    color: #39d71d;
    background: #e4ffdf;
}

.item_pay_status_item.deactivate {
    color: #626466;
    background: var(--For-fonts-6, #eef0f3);
}

.item_pay_status {
    width: 4.5626rem;
}

.item_order_number {
    width: 6.3125rem;
}

.item_program_type {
    width: 21.6875rem;
}

.item_buyer_name {
    width: 3.5635rem;
}

.item_payment_method {
    width: 7.875rem;
}

.item_total_amt {
    width: 5.6875rem;
}

.item_payment_date {
    min-width: 10.1875rem;
}

.item_payment_detail_button {
    width: 8.5rem;
}

.payment_detail_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    color: #3a5074;
    border: 0.0625rem solid var(--primary-01-navy-1, #3a5074);
    background: var(--For-fonts-white, #fff);
}
