.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.25rem 2rem;
}

.content_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.item_container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.item_button_container {
    display: flex;
    gap: 0.75rem;
}

.discount_input {
    box-sizing: border-box;
    width: 35.9375rem;
    height: 4rem;
    padding: 0 1.25rem;
    border-radius: 0.625rem;
    border: 1px solid var(--For-fonts-5, #e3e5ea);
    background: var(--For-fonts-white, #fff);
}

.discount_save_button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 4rem;
    padding: 0 3.75rem;
    border-radius: 0.625rem;
    color: #3a5074;
    border: 0.0625rem solid var(--primary-01-navy-1, #3a5074);
    background: var(--For-fonts-white, #fff);
}

.item_first_button {
    display: flex;
    box-sizing: border-box;
    padding: 1.25rem 1.5rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid #3a5074;
    background: #f6f9ff;
}

.program_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1.25rem 1.625rem;
    gap: 1.75rem;
    border-radius: 0.93rem;
    border: 0.0625rem solid #e3e5ea;
    background: #fff;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}

.price {
    display: flex;
    align-items: center;
}

.payment_content_container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.payment_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 5rem;
    padding: 2.25rem 1.75rem;
    min-width: 27.25rem;
    max-width: 27.25rem;
    height: 50.625rem;
    border-radius: 0.9375rem;
    background: #f6f7f9;
}

.total_price {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.payment_teram {
    margin-top: 3.25rem;
    color: #626466;
}

.payment_button {
    display: flex;
    width: 100%;
    margin-top: 3.25rem;
    height: 4rem;
    border-radius: 0.625rem;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #3a5074;
}

.payment_cancel_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
}
